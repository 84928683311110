import React, { FunctionComponent, useEffect, useReducer, useState } from "react";
import { Container, Form, Header, Icon, Input, Progress, Segment, Step, Button, Label } from "semantic-ui-react";
import "./CreateEditOrganization.css";
import { Field, useFormik } from "formik";
import * as yup from "yup";
import { Errors } from "../Errors";
import DropFile from "../DropFile/DropFile";
import { setTimeout } from "timers";
import { dropDownOptions, options } from "../../types";
import { createApiKeyNew, createClient, getAllCustomers, getClientByCustomerClientId, putClient, uploadImage } from "../../Services/BackendService";
import { useHistory } from "react-router-dom";
import { BlockPicker } from "react-color";
import { ThemePreview } from "./ThemePreview";
import { initState, reducer, StateValue, StateDispatch } from "./Context";
import { AuthFlowDnD } from "./AuthFlowDnD";
import { IsAdmin, MyCustomerId } from "../AuthGate";
import { parse } from "date-fns";
var FileSaver = require('file-saver');


type IProps = {
	match?: any;
};

export type opt = {
	name: string;
	id: number;
	icon: any;
};

export const CreateEditOrganization: FunctionComponent<IProps> = (props: IProps) => {
	const validationSchema = yup.object({
		NAME: yup.string().required("Een naam is verplicht").min(1, "Een naam moet uit minimaal 1 karakter bestaan"),
		TARGET: yup.string().required("Een target url is verplicht"),
		API: yup.string(),
		CUSTOMERID: yup.number().required().not([0], "Selecteer een beheerder"),
		API_END: yup.date().required("Een vervaldatum is verplicht").not([""], "Een vervaldatum is verplicht")
	});

	const history = useHistory();

	const [state, dispatch] = useReducer(reducer, initState);
	const [orgOptions, setOrgOptions] = useState<dropDownOptions[]>([]);

	const formik = useFormik({
		initialValues: {
			NAME: "",
			TARGET: "",
			API: "",
			CUSTOMERID: !IsAdmin ? MyCustomerId : 0,
			API_END: ""
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
		},
	});

	/**
	 * If customer ID changes, update the available services. 
	 */
	useEffect(() => {
		if (formik.values.CUSTOMERID > 0) {
			const currCustomer = state.allCustomers.find((c) => c.id === formik.values.CUSTOMERID);
			if (currCustomer?.serviceIds !== undefined) {
				dispatch({
					type: "setOptions",
					data: options
						.filter((opt) => currCustomer?.serviceIds.includes(opt.id))
						.map((k) => {
							const custom: any = {
								id: `${k.id}`,
								content: (
									<>
										<p>{k.name} </p>
										<Icon name={k.icon as any} />
									</>
								),
							};
							return custom;
						}),
				});
				dispatch({ type: "setApiAccess", data: currCustomer.serviceIds });
			}
		}
	}, [formik.values.CUSTOMERID]);

	/**
	 * If the customerclientid URL parameter changes, retreive te corresponding client and customer from the backend then set the form values.
	 */
	useEffect(() => {
		getAllCustomers().then((res) => {
			dispatch({ type: "setAllCustomers", data: res.data });
			const currCustomer = res.data.find((c) => c.id === (!IsAdmin ? MyCustomerId : formik.values.CUSTOMERID));
			console.log(currCustomer?.serviceIds)
			dispatch({ type: "setApiAccess", data: currCustomer?.serviceIds ?? [] });
			setOrgOptions(res.data.map((opt) => ({ key: opt.id!, text: opt.name + " id: " + opt.id!, value: opt.id! })));
		});
		if (props.match.params.customerclientid !== undefined) {
			getClientByCustomerClientId(parseInt(props.match.params.customerclientid)).then((res) => {
				formik.setValues({ NAME: res.data.name, TARGET: res.data.target, API: res.data.apiKey?.apiKeyId?.toString() ?? "", CUSTOMERID: res.data.customerId ?? 0, API_END: res.data.apiKey?.expiryDate?.slice(0, 10) ?? "" });
				// flow.flow.map((opt) => options.find((p) => p.id === opt)?.id ?? 0)      find service ID from flow (order)
				dispatch({ type: "setFlow", data: res.data.flows?.map((flow) => ({ name: flow.name, description: flow.description, id: flow.id, flow: flow.flow.map((opt) => options.find((p) => p.id === opt)?.id ?? 0) })) ?? [] });
				dispatch({ type: "setThemeColor", data: res.data.themeColor ?? "#22194d" });
				dispatch({ type: "setLoading", data: { ...state.loading, all: false } });
			});
		} else {
			dispatch({ type: 'setLoading', data: { ...state.loading, all: false } })
		}
		return () => {
			dispatch({ type: "setLoading", data: { ...state.loading, all: true } });
		}
	}, [props.match.params.customerclientid]);


	/**
	 * Adds/Removes service ID to/from ALL flows and ORG if CUSTOMER has access to it
	 * TODO: update APIkey on backend to update access too. 
	 * @param val The service ID to add/remove
	 */
	const addremove = (val: number) => {
		if (state.organization?.apiAccess?.includes(val) && state.organization.flows[state.currentflow] !== undefined) {
			dispatch({ type: "setApiAccess", data: [...state.organization.apiAccess.filter((e) => e !== val)] });
			const old = [...state.organization.flows];
			old.forEach(element => {
				element.flow = element.flow.filter((e) => e !== val);
			});
			dispatch({ type: "setFlow", data: old });
		} else {
			if (state.allCustomers.find((e) => e.id === formik.values.CUSTOMERID)?.serviceIds.includes(val) && state.organization.flows[state.currentflow] !== undefined) {
				dispatch({ type: "setApiAccess", data: [...(state.organization?.apiAccess ?? []), val] });
				const old = [...state.organization.flows];
				old[state.currentflow].flow = [...old[state.currentflow].flow, val];
				dispatch({ type: "setFlow", data: old });
			}
		}
	};

	/**
	 * Upload the selected png file (will be saved in wwwroot/CUSTOMERCLIENTID/CUSTOMERCLIENTID_logo.png)
	 * @param file The file dropped/selected in/from the dropzone component
	 */
	const callBackImage = (file: any[]) => {
		if (props.match.params.customerclientid !== undefined) {
			file.forEach((file) => {
				const reader = new FileReader();
				reader.onabort = () => console.log("file reading was aborted");
				reader.onerror = () => console.log("file reading has failed");
				reader.onload = function (event) {
					uploadImage(file, props.match.params.customerclientid);
					dispatch({ type: "setLogo", data: event.target!.result as any });
				};
				reader.readAsDataURL(file);
			});
		}
	};

	/**
	 * Edit organization by customerclientid
	 */
	const editOrganization = () => {
		putClient({ name: formik.values.NAME, target: formik.values.TARGET, customerClientId: parseInt(props.match.params.customerclientid.toString()), themeColor: state.organization.themeColor ?? "#22194d", apiKey: { expiryDate: formik.values.API_END } }).then((res) =>
			console.log(res),
		);
	};

	/**
	 * Create organization with CUSTOMERID
	 */
	const createOrganization = () => {
		if (window.confirm("Er zal een client aangemaakt worden onder de beheerder " + orgOptions.find((org) => org.value === formik.values.CUSTOMERID)?.text + " klopt dat? De beheerder van een client kan niet worden aangepast.")) {
			createClient({ name: formik.values.NAME, target: formik.values.TARGET, customerId: parseInt(formik.values.CUSTOMERID.toString()), themeColor: state.organization.themeColor ?? "#22194d", apiKey: { expiryDate: formik.values.API_END} }).then((res) => {
				var blob = new Blob([`API-key ${formik.values.NAME}: ${res.data.apiKey?.apiKey}`], {
					type: "text/plain;charset=utf-8;",
				});
				FileSaver.saveAs(blob, `API-key ${formik.values.NAME}.txt`);
				history.push(`/organization/${res.data.customerClientId?.toString()}`);
			});
		}
	};

	/**
	 * Create new API key, if an API key exists for this customerclient show a warning.
	 */
	const createApiKey = () => {
		if (formik.values.API.length > 1) {
			if (prompt(`Weet je zeker dat je een nieuwe API-key wilt genereren? De organisatie zal hun koppeling moeten updaten. Typ '${formik.values.NAME}' om door te gaan.`) === formik.values.NAME) {
				dispatch({ type: "setLoading", data: { ...state.loading, apikey: true } });
				setTimeout(() => {
					dispatch({ type: "setLoading", data: { ...state.loading, apikey: false } });
					createApiKeyNew(parseInt(props.match.params.customerclientid)).then((res) => {
						alert("Uw nieuwe API-key is " + res.data.apiKey + ".");
						formik.setFieldValue("API", res.data.apiKey);
						var blob = new Blob([`API-key ${formik.values.NAME}: ${res.data.apiKey}`], {
							type: "text/plain;charset=utf-8;",
						});
						FileSaver.saveAs(blob, `API-key ${formik.values.NAME}.txt`);
					});
				}, 1000);
				dispatch({ type: "setApiAccess", data: [] });
			}
		} else {
			dispatch({ type: "setLoading", data: { ...state.loading, apikey: true } });
			if (props.match.params.customerclientid !== undefined) {
				createApiKeyNew(parseInt(props.match.params.customerclientid)).then((res) => {
					dispatch({ type: "setLoading", data: { ...state.loading, apikey: false } });
					alert("Uw nieuwe API-key is " + res.data.apiKey + ".");
					formik.setFieldValue("API", res.data.apiKey);
					var blob = new Blob([`API-key ${formik.values.NAME}: ${res.data.apiKey}`], {
						type: "text/plain;charset=utf-8;",
					});
					FileSaver.saveAs(blob, `API-key ${formik.values.NAME}.txt`);
				});
			} else {
				formik.setFieldError("API", "Voordat er Voordat er een API-key aangemaakt kan worden moet de organisatie opgeslagen worden.");
				setTimeout(() => formik.setFieldError("API", undefined), 1000);
			}
		}
	};

    const handleChange = (e: any, data: any) =>{
		if (data && data.name) {
		  formik.setFieldValue(data.name,data.value);
		}
	  }

	return (
		<StateValue.Provider value={state}>
			<StateDispatch.Provider value={dispatch}>
				<Container fluid className="cont">
					<Header as="h1"> {props.match.params.id === undefined ? "Organisatie aanmaken" : formik.values.NAME + " aanpassen"} </Header>
					<Header as="h4" attached="top">
						Informatie
					</Header>
					<Segment attached loading={state.loading.all}>
						<Form onSubmit={() => formik.handleSubmit()}>
							<Form.Input label="Naam" error={formik.touched.NAME && formik.errors.NAME} onFocus={() => formik.setFieldTouched("NAME", true)} name="NAME" id="NAME" placeholder="Naam" onChange={(e) => formik.handleChange(e)} value={formik.values.NAME}  />
							<Form.Input label="Target URL" error={formik.touched.TARGET && formik.errors.TARGET} onFocus={() => formik.setFieldTouched("TARGET", true)} name="TARGET" id="TARGET" placeholder="https://www.google.com" onChange={(e) => formik.handleChange(e)} value={formik.values.TARGET} />
							<Form.Field
								disabled={(props.match.params.customerclientid !== "0" && props.match.params.customerclientid !== undefined) || !IsAdmin}
								value={!IsAdmin ? MyCustomerId : formik.values.CUSTOMERID}
								label="Beheerd door"
								control="select"
								id="CUSTOMERID"
								name="CUSTOMERID"
								onFocus={() => formik.setFieldTouched("CUSTOMERID", true)}
								error={formik.touched.CUSTOMERID && formik.errors.CUSTOMERID}
								onChange={(e: any) => formik.setFieldValue("CUSTOMERID", parseInt(e.target.value))}>
								{orgOptions.map((opt) => (
									<option key={opt.key} value={opt.value}>
										{opt.text}
									</option>
								))}
							</Form.Field>
						</Form>
					</Segment>

					<Header as="h4" attached>
						Logo en uiterlijk
            </Header>

					<Segment attached loading={state.loading.all}>

						<ThemePreview logo={state.organization.logo ?? undefined} NAME={formik.values.NAME} customerclientid={props.match.params.customerclientid} themeColor={state.organization.themeColor} />

						<Segment basic className="colorpicker" loading={state.loading.all}>
							<span>
							<BlockPicker
								
								width="140px"
								colors={["#D9E3F0", "#F47373", "#697689", "#37D67A", "#2CCCE4", "#555555", "#dce775", "#ff8a65"]}
								color={state.organization.themeColor}
								onChangeComplete={(color) => dispatch({ type: "setThemeColor", data: color.hex })}
							/>
							</span>
							<DropFile
								disabled={props.match.params.customerclientid === undefined}
								callBack={(e) => callBackImage(e)}
								text={props.match.params.customerclientid === undefined ? `Sla eerst de organisatie op, voor u een logo toevoegt` : `Upload een logo`}
								type="image/png"
								error="Dit is geen X"
								icon="file image outline"
								color={state.organization.themeColor ?? "#22194d"}
							/>
						</Segment>

					</Segment>



					<Header as="h4" attached>
						API access
            </Header>
					<Segment attached loading={state.loading.all}>
						<span><Form>
							<Form.Field>
								<label>Vervaldatum</label>
								<input type="date" min={new Date().toISOString().slice(0, 10)} id="API_END" name="API_END" value={formik.values.API_END} onChange={formik.handleChange}  required />
							</Form.Field>

							<Input loading={state.loading.apikey} value={formik.values.API} icon={<Icon name="add" disabled={formik.values.API === ""} link onClick={() => createApiKey()} />} placeholder="Generate API key" readOnly fluid type="text" />
							<Progress percent={formik.values.API === "" ? 0 : 100} autoSuccess attached="bottom" />
						</Form>

						</span>
						<Step.Group fluid={true} widths={7}>
							{options.map((el) => (
								<Step active={state.organization?.apiAccess?.includes(el.id)} onClick={() => addremove(el.id)} key={el.id}>
									<Icon name={el.icon as any} />
									<Step.Content>
										<Step.Title>{el.name}</Step.Title>
										<Step.Description>
											{state.organization?.apiAccess?.includes(el.id) ? "Wel " : "Geen "} toegang tot {el.name} controle
                                </Step.Description>
									</Step.Content>
								</Step>
							))}
						</Step.Group>
					</Segment>

					<AuthFlowDnD customerclientid={props.match.params.customerclientid} />

					<Segment floated="right" attached>
						<Button
							floated="right"
							basic
							onClick={() => (props.match.params.customerclientid === undefined ? createOrganization() : editOrganization())}
							content={props.match.params.customerclientid !== undefined ? "Opslaan" : "Aanmaken"}
							disabled={formik.values.CUSTOMERID === 0 || formik.values.NAME === "" || state.organization.themeColor === undefined || formik.values.TARGET === "" || formik.values.API_END === ""}
						/>
					</Segment>
					<Errors errors={formik.errors} />
				</Container>
			</StateDispatch.Provider>
		</StateValue.Provider>
	);
};

