// DTO's
export type GetCustomerDto = {
    name: string;
    website: string;
    phone: string;
    email: string;
    id?: number;
    apiAccess?: number[];
};

export type LoginDto = {
    apiKeyId: number;
    apiKey: string;
    expiryDate: Date;
};

export type GetApiKeysDto = {
    clientName?: string;
    expiryDate?: string;
    serviceAccess?: string[];
    apiKey?: string;
	apiKeyId?: number;
    accessFlows?: flow[];
    customerClientId?: number;
};

export type flow = {
    id?: number;
    name: string;
	description?: string;
    flow: number[];
};

export type FlowAddDto = {
    name: string;
    id: number;
    customerClientId: number;
};

export type ClientCreateEditDto = {
    name: string;
    target: string;
    id?: number;
    customerId?: number;
    apiKey?: GetApiKeysDto;
    flows?: flow[];
    customerClientId?: number;
    logo?: any;
    themeColor?: string;
};

export type ClientsByCustomerId = {
    name: string;
    target: string;
    id?: number;
    customerId?: number;
    customerClientId?: number;
    themeColor?: string;
    isActive: boolean;
};

export type isLoading = {
    apikey: boolean;
    logo: boolean;
    all: boolean;
};

export type optionprops = {
    option: number;
    index: any;
};

export type dropDownOptions = {
    key: string | number;
    value: any;
    text: string;
};

export type optionsType = {
    name: string;
    id: number;
    icon: any;
    callback?: string;
};

export const options: optionsType[] = [
    {
        name: "Idin",
        id: 1,
        icon: "address card outline",
    },
    {
        name: "Iban",
        id: 2,
        icon: "credit card outline",
    },
    {
        name: "AgeCheck",
        id: 3,
        icon: "user",
    },
    {
        name: "PaspoortCheck",
        id: 4,
        icon: "user",
    },
    {
        name: "PepCheck",
        id: 5,
        icon: "user",
    },
	{
		name: "NategisCheck",
		id: 6,
		icon: "clipboard list"
	}, 
	{
		name: "CurateleCheck",
		id: 7,
		icon: "user times"
	}
];

export type auth = {
    id: string;
    callbacktarget?: string;
};

export type authflow = {
    flow: auth[];
    name: string;
    id: string;
    order?: number;
};

export type organization = {
    name?: string;
    target?: string;
    apiAccess?: number[];
    flows: flow[];
    id?: number;
    logo?: any;
    themeColor?: any;
};

export type customer = {
    name?: string;
    phone?: string;
    email?: string;
    website?: string;
    id?: number;
};

export type client = {
    name?: string;
    target?: string;
    id?: number;
};

export interface Dictionary<T> {
    [Key: string]: T;
}

export type AllClientsDto = { id: string; name: string; customerName: string; customerId: number; themeColor: string; customerClientId: number; serviceIds: number[] };
export type AllClientsDropdownOption = {
    key: string;
    value: string;
    text: string;
    customerName: string;
    customerId: number;
    themeColor: string;
    id: string;
};

export type TransactionStatistic = {
	date: string;
	clientId: number;
	clientName: string;
	clientLogo: string;
	clientColor: string;
	transactionId: number;
	transactionStatusId: number;
	completedParent: boolean;
	validated: boolean;
	service: string;
}

export interface TransactionCallByDay {
    themeColor: string;
    count: number;
    clientName: string;
    date: Date;
}

export interface IgetHomeScreenStatisticsByCustomerId {
    name: string;
    themeColor: string;
    transactionCallByDay: TransactionCallByDay[];
}
