import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu, Label, Input, Dropdown, Segment, Icon, Sidebar, Header, Divider, Image, Button } from "semantic-ui-react";
import { getAllClients, getRecentCustomers } from "../Services/BackendService";
import { AllClientsDropdownOption, customer } from "../types";
import AuthGate, { IsAdmin, MyCustomerId } from "./AuthGate";
import "./Sidebar.css";
type IProps = {};

export const MenuSidebar: FunctionComponent<IProps> = (props: IProps) => {
	const [options, setOptions] = useState<AllClientsDropdownOption[]>();
	const [optionsCustomers, setOptionsCustomers] = useState<any[]>();
	const [filteredOptions, setFilteredOptions] = useState<any[]>();
	const [filteredOptionsCustomers, setFilteredOptionsCustomers] = useState<any[]>();
	const [searchString, setSearchString] = useState<string>("");
	const [searchStringCustomer, setSearchStringCustomer] = useState<string>("");

	const [recentCustomers, setRecentCustomers] = useState<customer[]>([]);

	useEffect(() => {
		getAllClients().then((res) => {
			setOptions(res.data.map((opt: any) => ({ key: opt.id + "client", value: opt.customerClientId.toString(), text: opt.name, customerName: opt.customerName, customerId: opt.customerId, themeColor: opt.themeColor, id: opt.id })));
		});

		getRecentCustomers().then((res) => {
			setRecentCustomers(res.data);
		});
	}, []);

	useEffect(() => {
		setFilteredOptions(options?.filter((opt) => opt.text.toUpperCase().includes(searchString?.toUpperCase() ?? "")));
	}, [searchString]);

	useEffect(() => {
		setFilteredOptionsCustomers(recentCustomers?.filter((opt) => opt.name?.toUpperCase().includes(searchStringCustomer?.toUpperCase() ?? "")));
	}, [searchStringCustomer]);

	return (
		<Menu vertical className="sidebar">
			{/* <Segment basic> */}
			<div style={{ textAlign: "center", paddingTop: "1.5em", paddingBottom: "0.5em" }}>
				{" "}
				<Image src="/logo.png" size="small" centered as={NavLink} to="/" />
			</div>
			<Divider />


			<Dropdown.Menu className="dropdownmenu">
				<Header className="link" as={NavLink} to="/organization">
					Organisaties
				</Header>
				<Dropdown.Header content="Zoek organisaties" />
				<Input icon="search" iconPosition="left" name="search" onChange={(e) => setSearchString(e.target.value)} />
				<Dropdown.Header content="Recent" />
				<Dropdown.Divider />
				<div className="dropdownitemsmaxheight">
					{searchString === ""
						? options?.map((opt) => <Dropdown.Item key={opt.id} label={<><Label as={NavLink} to={`/dashboard/stats/${opt.customerId}/${opt.text}`} icon={<Icon name="pie chart" size="large" fitted />} style={{ color: opt.themeColor, backgroundColor: "white" }} size="mini" /> <Label as={NavLink} to={"/organization/" + opt.value} icon={<Icon name="edit outline" size="large" fitted />} style={{ color: opt.themeColor, backgroundColor: "white" }} size="mini" /></>} text={opt.text} />)
						: filteredOptions?.map((opt) => <Dropdown.Item key={opt.id} label={<><Label as={NavLink} to={`/dashboard/stats/${opt.customerId}/${opt.text}`} icon={<Icon name="pie chart" size="large" fitted />} style={{ color: opt.themeColor, backgroundColor: "white" }} size="mini" /> <Label as={NavLink} to={"/organization/" + opt.value} icon={<Icon name="edit outline" size="large" fitted />} style={{ color: opt.themeColor, backgroundColor: "white" }} size="mini" /></>} text={opt.text} />)}
				</div>
				<div style={{ textAlign: "center", paddingTop: "1.5em", paddingBottom: "0.5em" }}>
					<Button basic as={NavLink} to="/organization" icon="add" size="tiny" />
				</div>
			</Dropdown.Menu>

			<AuthGate>
				<Dropdown.Menu className="dropdownmenu">
					<Header as="h3" className="link">
						Klanten
                </Header>
					<Dropdown.Header content="Zoek klanten" />
					<Input icon="search" iconPosition="left" name="search" onChange={(e) => setSearchStringCustomer(e.target.value)} />
					<Dropdown.Header content="Recent" />
					<Dropdown.Divider />
					<div style={{ maxHeight: "250px", overflowY: "scroll", height: "250px" }}>
						{searchStringCustomer === ""
							? recentCustomers?.map((opt) => <Dropdown.Item key={opt.name} label={{ color: "grey", empty: true, circular: true }} text={opt.name} as={NavLink} to={"/customer/" + opt.id} />)
							: filteredOptionsCustomers?.map((opt) => <Dropdown.Item key={opt.name} label={{ color: "grey", empty: true, circular: true }} text={opt.name} as={NavLink} to={"/customer/" + opt.id} />)}
					</div>
					<div style={{ textAlign: "center", paddingTop: "1.5em", paddingBottom: "0.5em" }}>
						<Button basic as={NavLink} to="/customer" icon="add" size="tiny" />
					</div>
				</Dropdown.Menu>
			</AuthGate>
		</Menu>
	);
};
