function RouteCheck(props: IProps) {
	return window.location.href.includes(props.urlcondition ?? "") ? null : props.children
}

type IProps = {
	children: any
	urlcondition?: string
}

export default RouteCheck

