import React, { FunctionComponent, useEffect, useReducer, useState } from "react";
import { Container, Form, Header, Segment, Button, List, Grid, Icon, Step, Image, Label } from "semantic-ui-react";
import "./CreateEditCustomer.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Errors } from "../Errors";
import { client, ClientsByCustomerId, customer, options } from "../../types";
import { createCustomer, customerService, editCustomer, getClientsByCustomerId, getCustomer, toggleClientActive, toggleIsActiveCustomerClient } from "../../Services/BackendService";
import { NavLink, useHistory } from "react-router-dom";
type IProps = {
	match?: any;
};

type state = {
	customer: customer;
	loading: boolean;
};

export const CreateEditCustomer: FunctionComponent<IProps> = (props: IProps) => {
	const validationSchema = yup.object({
		NAME: yup.string().required("Een naam is verplicht").min(1, "Een naam moet uit minimaal 1 karakter bestaan"),
		PHONE: yup.string().required("Een telefoonnummer is verplicht").min(1, "Een telefoonnummer moet uit minimaal 1 karakter bestaan"),
		EMAIL: yup.string().required("Een e-mail adres is verplicht").min(1, "Een e-mail adres moet uit minimaal 1 karakter bestaan").email("Dat is geen geldig e-mail adres"),
		WEBSITE: yup.string().required("Een website adres is verplicht").min(1, "Een website moet uit minimaal 1 karakter bestaan"),
	});

	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(false);
	const [clients, setClients] = useState<ClientsByCustomerId[]>([]);
	const [apiAccess, setApiAccess] = useState<number[]>([]);

	/**
	 * If route changes, retreive customer to edit
	 */
	useEffect(() => {
		if (props.match.params.id !== undefined) {
			setLoading(true);
			getCustomer(props.match.params.id).then((res) => {
				formik.setValues({ NAME: res.data.name, EMAIL: res.data.email, PHONE: res.data.phone, WEBSITE: res.data.website });
				setApiAccess(res.data.apiAccess ?? [])
				setLoading(false);
			});

			getClientsByCustomerId(parseInt(props.match.params.id)).then((res) => {
				setClients(res.data);
				setLoading(false)

			});
		}
		return () => {
			setLoading(false);
		}

	}, [props.match.params.id]);


	/**
	 * Create customer and change route to match new ID
	 */
	function postCustomer() {
		createCustomer({ name: formik.values.NAME, email: formik.values.EMAIL, website: formik.values.WEBSITE, phone: formik.values.PHONE, }).then((res) => history.push(`/customer/${res.data.id}`));
	}

	/**
	 * Edit customer
	 */
	function putCustomer() {
		editCustomer({ name: formik.values.NAME, email: formik.values.EMAIL, website: formik.values.WEBSITE, phone: formik.values.PHONE, id: parseInt(props.match.params.id) }).then((res) => {
		});
	}

	const formik = useFormik({
		initialValues: {
			NAME: "",
			EMAIL: "",
			PHONE: "",
			WEBSITE: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
		},
	});


	/**
	 * Toggle access to service by service ID
	 * @param val  Id of service
	 */
	const addremove = (val: number) => {
		customerService(parseInt(props.match.params.id), val);
		if (apiAccess?.includes(val)) {
			setApiAccess([...apiAccess.filter((e) => e !== val)])
		} else {
			setApiAccess([...(apiAccess ?? []), val]);
		}
	};

	/**
	 * Toggle isActive on client
	 * @param id customerClientId
	 * @param index index in list
	 */
	const toggleIsActive = (id: number, index: number) => {
		toggleIsActiveCustomerClient(id).then(res => {
			const old = [...clients];
			old[index].isActive = !old[index].isActive;
			setClients(old);
		})
	}
	const toggleIsActiveCustomer = () => {

	}
	

	return (
		<>
			<Container fluid className="cont">
				<Header as="h1"> {props.match.params.id === undefined ? "Klant aanmaken" : "Klant aanpassen"} </Header>
				<Header as="h4" attached="top">
					Informatie
            </Header>
				<Segment attached loading={loading}>
					<Form onSubmit={() => formik.handleSubmit()}>
						<Form.Input name="NAME" id="NAME" label="Naam" placeholder="Bedrijf" onChange={(e) => formik.handleChange(e)} value={formik.values.NAME} />
						<Form.Input label="E-mail" name="EMAIL" id="EMAIL" placeholder="persoon@bedrijf.nl" onChange={(e) => formik.handleChange(e)} value={formik.values.EMAIL} />
						<Form.Input label="Telefoon" name="PHONE" id="PHONE" placeholder="0612345678" onChange={(e) => formik.handleChange(e)} value={formik.values.PHONE} />
						<Form.Input label="Website" name="WEBSITE" id="WEBSITE" placeholder="www.bedrijf.nl" onChange={(e) => formik.handleChange(e)} value={formik.values.WEBSITE} />
					</Form>
				</Segment>
				<Header as="h4" attached>
					API access
            </Header>
				<Segment attached loading={loading}>
					<Step.Group fluid={true} widths={7}>
						{options.map((el) => (
							<Step active={apiAccess?.includes(el.id)} onClick={() => addremove(el.id)} key={el.id} disabled={props.match.params.id === undefined}>
								<Icon name={el.icon as any} />
								<Step.Content>
									<Step.Title>{el.name}</Step.Title>
									<Step.Description>
										{apiAccess?.includes(el.id) ? "Wel " : "Geen "} toegang tot {el.name} controle
                                </Step.Description>
								</Step.Content>
							</Step>
						))}
					</Step.Group>
				</Segment>
				<Segment attached loading={loading}>
					<List.Header>
						<h2> Aangesloten Organisaties</h2>
					</List.Header>
					<List divided verticalAlign='middle' relaxed>

						{clients.map((el, index) => (
							<List.Item  style={ !el.isActive ? {backgroundColor: "#EFEFEF"} : {}}>
								<List.Content floated='right'>
									<Button basic onClick={() => toggleIsActive(el.customerClientId!, index)}>{el.isActive ? "Actief" : "Inactief"}</Button>
									<Button basic as={NavLink} to={`/organization/${el.customerClientId}`}>Edit</Button>
								</List.Content>
								<Image avatar style={{ backgroundColor: el.themeColor ?? "grey" }} />
								<List.Content verticalAlign="middle">{el.name}</List.Content>
							</List.Item>))}
					</List>
				</Segment>
				<Segment floated="right" attached>
					<Button
						floated="right"
						basic
						onClick={() => (props.match.params.id !== undefined ? putCustomer() : postCustomer())}
						content={props.match.params.id !== undefined ? "Opslaan" : "Aanmaken"}
						disabled={Object.values(formik.errors).length !== 0}
					/>
				</Segment>
				<Errors errors={formik.errors} />

			</Container>

		</>
	);
};
