var FileSaver = require('file-saver');

export const saveFile = (fileName: string, data: any) => {
	// Create a blob of the data
	var fileToSave = new Blob([JSON.stringify(data)], {
		type: 'application/json',
	});
	
	// Save the file
	FileSaver.saveAs(fileToSave, fileName);
}