import React, { FunctionComponent } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Container, Form, Grid, Segment, Image, Button } from "semantic-ui-react";
import "./Login.css";
import { login } from "../../Services/BackendService";
import { useHistory } from "react-router-dom";
import { Errors } from "../Errors";

type IProps = {};

export const Login: FunctionComponent<IProps> = (props: IProps) => {
	const validationSchema = yup.object({
		USERNAME: yup.string().required("Een gebruikersnaam is verplicht"),
		PASSWORD: yup.string().required("Een wachtwoord is verplicht"),
	});

	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			USERNAME: "",
			PASSWORD: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
		},
	});

	const loginOnClick = () => {
		login(formik.values.USERNAME, formik.values.PASSWORD)
			.then((res) => {
				if (res.status === 200) {
					history.push("/");
					window.location.reload();
				}
			})
			.catch((error) => {
				formik.setErrors({ USERNAME: "De inloggegevens zijn onjuist." });
			});
	};

	return (
		<Grid className="login" columns="2">
			<Grid.Column className="center">
				<Segment basic>
					<Segment padded="very" className="form">
						<Form onSubmit={() => formik.handleSubmit()}>
							<Form.Input name="USERNAME" id="USERNAME" label="Gebruikersnaam" placeholder="Username" onChange={(e) => formik.handleChange(e)} />
							<Form.Input type="password" label="Wachtwoord" name="PASSWORD" id="PASSWORD" placeholder="Password" onChange={(e) => formik.handleChange(e)} />
							<Button basic floated="right" onClick={() => loginOnClick()}>
								Login
                        </Button>
						</Form>


					</Segment>
					<Errors errors={formik.errors} />
				</Segment>
			</Grid.Column>

			<Grid.Column className="loginimage">
				<Image src="/loginsvg.svg" style={{ height: "100%", objectFit: "cover" }} />
			</Grid.Column>
		</Grid>
	);
};
