import { formatDistanceToNow } from "date-fns";
import { format } from "path";
import React, { FunctionComponent, useEffect, useState } from "react";
import { XYPlot, XAxis, YAxis, VerticalGridLines, HorizontalGridLines, VerticalBarSeries, SearchableDiscreteColorLegend, AreaSeries, DiscreteColorLegend, makeWidthFlexible, Hint, FlexibleWidthXYPlot, Crosshair } from "react-vis";
import { Grid, Segment } from "semantic-ui-react";
import { getHomeScreenStatisticsByCustomerId, getTransactionStatisticsByCustomerId } from "../../Services/BackendService";
import { groupBy } from "../../Services/GroupBy";
import { IgetHomeScreenStatisticsByCustomerId, TransactionStatistic } from "../../types";
import { MyCustomerId } from "../AuthGate";

export const HomeStatistics: FunctionComponent = () => {
    const BarSeries = VerticalBarSeries;

    const [data, setData] = useState<IgetHomeScreenStatisticsByCustomerId[]>();
    const [dataParsed, setDataParsed] = useState<any[]>();

    useEffect(() => {
        getHomeScreenStatisticsByCustomerId(MyCustomerId).then((res) => {
            setData(res.data);
            var parsed = res.data.map((ob) => ({ key: ob.name + "st", name: ob.name, themeColor: ob.themeColor, transactions: ob.transactionCallByDay.map((tbd) => ({ y: tbd.count, x: tbd.date.toString().slice(5, 10) })) }));
            setDataParsed(parsed);
        });
    }, []);

    
    return (
        <div>
            <Grid>
                <Grid.Column width="thirteen">
                    <div className="datavis">
                        <FlexibleWidthXYPlot xType="ordinal" height={300} stackBy="y">
                            <VerticalGridLines tickTotal={10} />
                            <HorizontalGridLines tickTotal={10}/>
                            <XAxis tickSize={3} tickTotal={data?.length ?? 0} title="Datum" />
                            <YAxis tickSize={3} title="API-calls per dag" />
                            {dataParsed?.map((s) => (
                                <BarSeries
                                    key={`${s.name}ccc`}
                                    width={1}
                                    barWidth={1}
                                    data={s.transactions}
                                    color={s.themeColor}
                                />
                            ))}
                        </FlexibleWidthXYPlot>
                    </div>
                </Grid.Column>
                <Grid.Column
                    width="three"
                    style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexFlow: "column",
                    }}>
                    <DiscreteColorLegend height={300} width={150} items={dataParsed?.map((x) => ({ title: x.name, color: x.themeColor, key: x.name + x.themeColor })) ?? ([] as any)} />
                </Grid.Column>
            </Grid>
        </div>
    );
};
