import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "semantic-ui-css/semantic.min.css";

import { MenuSidebar } from "./Components/Sidebar";
import { Sidebar } from "semantic-ui-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CreateEditOrganization } from "./Components/Organization/CreateEditOrganization";
import { Login } from "./Components/Login/Login";
import RouteCheck from "./Components/RouteCheck/RouteCheck";
import { Home } from "./Components/Home/Home";
import { CreateEditCustomer } from "./Components/Customer/CreateEditCustomer";
import { TransactionStatisticsByCustomer } from "./Components/Dashboard/TransactionStatisticsByCustomer";

function App() {
	return (
		<>
			<Router>
				<Route
					exact
					path="/login"
					component={Login}
				/>
				<RouteCheck urlcondition="login">
					<MenuSidebar />
					<Sidebar.Pusher>
						<Route
							exact
							path="/"
              component={Home}
						/>
						<Route
							exact
							path="/organization"
							component={CreateEditOrganization}
						/>
						<Route
							exact
							path="/organization/:customerclientid"
							component={CreateEditOrganization}
						/>
						<Route
							exact
							path="/customer"
							component={CreateEditCustomer}
						/>
						<Route
							exact
							path="/customer/:id"
							component={CreateEditCustomer}
						/>
						<Route exact path="/dashboard" component={CreateEditOrganization} />
						<Route
							exact
							path="/dashboard/:id"
							component={CreateEditOrganization}
						/>
						<Route
							exact
							path="/dashboard/stats/:customerid/:clientName"
							component={TransactionStatisticsByCustomer}
						/>
						<Route
							exact
							path="/dashboard/stats/:customerid"
							component={TransactionStatisticsByCustomer}
						/>
					</Sidebar.Pusher>
				</RouteCheck>
			</Router>
		</>
	);
}

export default App;
