import React, { Fragment, useContext, useEffect } from "react"
import { Segment, Grid, Button, Container, Header, Image } from "semantic-ui-react"
import { StateDispatch, StateValue } from "./Context"
import SuccessSvg from "./SuccessSvg"

type ThemePreviewProps = {
	logo: any,
	customerclientid: number,
	NAME: string,
	themeColor: string,
}

export const ThemePreview = (props: ThemePreviewProps) => {
	
	return <Container style={{ width: "35%", marginTop: "1em" }}>
		<Segment.Group className="containersegment">
			<Segment>
				<Header as="h3" style={{ marginBottom: 0 }}>
					<Image
						src={props.logo ?? (props.customerclientid !== undefined ? `/${props.customerclientid}/${props.customerclientid}_logo.png` : "")}
						size="massive"
					/>{" "}
					{props.NAME}
				</Header>
			</Segment>

			<Fragment>
				<Segment>
					<Segment basic>
						<Grid columns={2} stackable textAlign="left">
							<Grid.Row>
								<Grid.Column verticalAlign="top">
									<Header as="h2">Gelukt!</Header>
									<Header as="h4">Uw gegevens zijn met succes gevalideerd!</Header>
								</Grid.Column>

								<Grid.Column verticalAlign="bottom">
									<SuccessSvg color={props.themeColor} />
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Segment>
			</Fragment>
			<Segment style={{ minHeight: "5em" }}>
				<Button floated="right" type="submit">
					Voorbeeld
                            </Button>
			</Segment>
		</Segment.Group>
	</Container>
}