import * as React from "react";

function SuccessSvg(props: any) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 1188.49 771.26"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          `.prefix__st1{fill:#b2b6bb}.prefix__st2{fill:#fc9d9d}.prefix__st3{fill:#242830}.prefix__st4{fill:#3f4249}.prefix__st5{fill:${props.color}}.prefix__st6{enable-background:new}.prefix__st6,.prefix__st7{opacity:.2}.prefix__st8{enable-background:new}.prefix__st8,.prefix__st9{opacity:.15}.prefix__st10{fill:#fff}.prefix__st11{fill:#868a91}.prefix__st12{fill:#21252b}.prefix__st13{opacity:.3}.prefix__st14,.prefix__st15{opacity:.3;enable-background:new}.prefix__st15{opacity:.8}.prefix__st16{fill:#dadddf}.prefix__st17{opacity:.5;fill:#fff;enable-background:new}`
        }
      </style>
	  <g id="svg">
      <path
        d="M131.36 355.75c-11.15.93-20.75 8.31-24.46 18.89L30.77 591.09c-1.55 4.44-2.01 9.19-1.29 13.83L55.24 769.3l555.62.36 18.48-268.47c.52-7.28-1.81-14.45-6.45-20.08L503.61 337.12c-5.94-7.12-14.97-10.94-24.2-10.17"
        fill="#f4f4f4"
      />
      <path
        className="prefix__st1"
        d="M199.28 766.82c.15.83-.31 1.65-1.03 1.96"
      />
      <path
        className="prefix__st2"
        d="M192.89 626.36l-35.6 40.3 25.4 19.6 29.8-27.2-19.6-32.7z"
      />
      <path
        className="prefix__st3"
        d="M162.09 660.06l-22.7 17.4c-2.5 1.9-3.2 5.4-1.7 8.1l43.6 80.5c.9 1.8 3 2.5 4.9 1.8 4-1.5 6.2-5.8 5-9.9l-.5-1.9c-2.5-8.8-3.8-17.8-3.9-27l-.6-42.5c0-2.6-1.9-4.9-4.4-5.5-8.3-1.9-14.5-8.9-15.5-17.4l-.2-2.1c-.4-1.7-2.6-2.6-4-1.5z"
      />
      <path
        className="prefix__st4"
        d="M173.09 648.36l114.8-133.2-1.2-186.2h62.9l-9.3 199.4c-.1 8.5-3.6 16.6-9.8 22.5l-133.1 122.1-24.3-24.6z"
      />
      <path
        className="prefix__st5"
        d="M361.69 167.86l-3.5 56.8-4.6-.7-4.1 105.3-133.6-.4-2.3-118-11.2-70.6 43.2-12.3 1.5-.4 5.5-1.6 23.4 20.1 24.9-20.4 6.6 2.1c.5.1.7.2.8.2h.1l.3.1.3.1 32 10.2c12.9 4.1 21.3 16.2 20.7 29.5z"
      />
      <path
        className="prefix__st5"
        d="M180.99 166.76l-7.8 110 50.6-20.2 11-124-31.2 7.5c-12.5 3-21.7 13.8-22.6 26.7z"
      />
      <path
        className="prefix__st3"
        d="M272.39 150.96l-8.4 133.1 11.4 12.2 10.7-12.2-6.1-133.7-4-2.7-3.6 3.3z"
      />
      <path
        className="prefix__st5"
        d="M458.29 157.76l-72.6 114.2c-5.4 8.5-18.1 7.3-21.9-2l-19.2-47.9 15.1-66.1 22 62 42-60.2h34.6z"
      />
      <path
        className="prefix__st2"
        d="M214.49 708.16l32.7.7-1.6 25.3-30.2 2.7-.9-28.7z"
      />
      <path
        className="prefix__st3"
        d="M230.49 721.46c8.7 0 15.9 6.9 16.2 15.6l.1 4c.1 1.6.3 3.2.7 4.7l4.5 16.6c.8 2.9-1.4 5.7-4.3 5.7h-37.2c-3.2 0-5.4-3.2-4.2-6.2l6.4-16c.9-2.2 1.4-4.6 1.5-7l.1-1.9c.3-8.5 7.4-15.5 16.2-15.5z"
      />
      <path
        className="prefix__st6"
        d="M352.89 242.46l-14.5-34.2c-.8-1.1-2.6-.7-2.7.7l-7.6 119.9 21.4.4 3.4-86.8z"
      />
      <path
        className="prefix__st6"
        d="M328.09 328.96l-11.9 200.1-103 110.8-.3-29.9-5.4 6.4-2.1 31.9-15.7 16.9 7.7 7.8 133.6-122.6c5.9-5.6 9.3-13.3 9.3-21.4l9.3-200h-21.5z"
      />
      <g className="prefix__st7">
        <path
          className="prefix__st6"
          d="M268.95 534.07l-4.13 75.39 1.5.08 4.13-75.39-1.5-.08z"
        />
      </g>
      <path
        className="prefix__st2"
        d="M306.99 230.86l6.1-9 1.5-9.6c.1-.4.4-.7.8-.8 2.7-.6 5.3 1.2 5.7 3.9l1.9 11.6 21.3-1.5c1.6 0 3 1.2 3.2 2.8.2 1.8-1.3 3.4-3.1 3.4h-1.7 1.8c1.8 0 3.4 1.4 3.5 3.2.2 1.9-1.2 3.7-3.2 3.8l-1.6.1 1.8-.2c1.8-.2 3.4 1.2 3.6 3 .2 1.8-1.2 3.4-3 3.6l-2.1.2 3-.3c1.3-.1 2.5.9 2.7 2.2.1 1.3-.8 2.4-2 2.6l-37.2 6-14.6 4.3 3.9-28.7 7.7-.6z"
      />
      <path
        className="prefix__st6"
        d="M234.79 237.46c-6.4-3.4-10.7-9.8-11.4-17l-3-30.8c-.1-.7-1-.7-1.1 0l-5 50.2-11.3 1.3c-.4 0-.7.4-.6.8 0 .4.4.7.8.6l103.9-11.8 1.2-1.8-73.5 8.5z"
      />
      <path
        className="prefix__st8"
        d="M349.19 247.36l-.1-.2c-.3-1.4-1.7-2.3-3-2l-.4.1-30.9 4.7-8.3-19.1h-2.3l-8.6 1 2.6 27.7s14.5-3.9 15-4l2.7-.4c9.6-1.6 28.9-4.7 30.9-5 2.8-.6 2.4-2.8 2.4-2.8z"
      />
      <g className="prefix__st9">
        <path
          className="prefix__st8"
          d="M335.19 231.66c0-.4.3-.7.7-.7l11.2-.9c-.5 1-1.5 1.7-2.6 1.7h-.1l-8.4.7c-.4-.1-.8-.4-.8-.8z"
        />
      </g>
      <g className="prefix__st9">
        <path
          className="prefix__st8"
          d="M335.69 239.16c0-.4.2-.7.6-.8l11.3-1.5c-.4 1-1.4 1.7-2.5 1.8h-.1l-8.6 1.2c-.3 0-.6-.3-.7-.7z"
        />
      </g>
      <path
        className="prefix__st6"
        d="M288.69 156.36l-.2-.2.2.2-12.2-3.3-11.7 3.4-19.2-28.4 1.5-.4 18.9 27.9 10.1-7.9 11.4 7.9 20.2-27.7c.5.1.7.2.8.2h.1l.3.1.3.1-20.5 28.1z"
      />
      <path
        className="prefix__st5"
        d="M176.69 246.46l122.6-14.9 4.2 26.2-116.2 33.9c-7.7 2.3-15.3-4.1-14.4-12.1l3.8-33.1z"
      />
      <path
        className="prefix__st6"
        d="M302.79 253.26l-109.8 26.3c-9.8 2.1-19-5.8-18.2-15.9l.2-2.2-2 18.1c-.9 8 6.7 14.4 14.4 12.1l46.6-13.7 69.6-20.3-.8-4.4z"
      />
      <path
        className="prefix__st3"
        d="M301.19 231.46l5.1 25.7 5-1.2-4.8-25.1-5.3.6z"
      />
      <path
        className="prefix__st3"
        d="M306.49 249.76c3.15 0 5.7-2.55 5.7-5.7 0-3.15-2.55-5.7-5.7-5.7-3.15 0-5.7 2.55-5.7 5.7 0 3.14 2.56 5.7 5.7 5.7z"
      />
      <path
        className="prefix__st6"
        d="M310.09 249.76l-2.4-12.3c-.4-.1-.8-.1-1.3-.1-1.5 0-2.8.5-3.9 1.3l2.4 12.1c.5.1 1 .2 1.5.2 1.4-.2 2.7-.6 3.7-1.2z"
      />
      <path
        className="prefix__st10"
        d="M306.49 249.06c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5z"
      />
      <path
        className="prefix__st4"
        d="M215.89 328.96l-1.9 390.9h33l32.5-324.7c.1-1.9 1.6-3.3 3.5-3.3s3.4 1.5 3.5 3.3l.9 30 5.1-96.2h-76.6z"
      />
      <path
        className="prefix__st6"
        d="M282.89 391.76c-19.8 0-37.1 16.1-37.1 35.9l-12.9 292.1h13.9l32.5-324.7c.2-1.8 1.8-3.3 3.6-3.3z"
      />
      <path
        className="prefix__st3"
        d="M349.09 340.86h-133.3l.1-11.9h133.7l-.5 11.9z"
      />
      <path
        className="prefix__st4"
        d="M252.89 328.96h-4.3v12.9h4.3v-12.9zM310.69 328.96h-4.3v12.9h4.3v-12.9z"
      />
      <path
        className="prefix__st1"
        d="M290.39 340.86h-15.7c-1.6 0-2.9-1.3-2.9-2.9v-6.1c0-1.6 1.3-2.9 2.9-2.9h15.7c1.6 0 2.9 1.3 2.9 2.9v6.1c0 1.6-1.3 2.9-2.9 2.9z"
      />
      <path
        className="prefix__st11"
        d="M223.59 737.96c-.2 0-.5-.1-.6-.3-.2-.3-.1-.8.2-1 .1 0 7.3-4.8 14.4 0 .3.2.4.7.2 1-.2.3-.7.4-1 .2-6.3-4.2-12.7 0-12.8 0-.1.1-.3.1-.4.1zM223.59 742.26c-.2 0-.5-.1-.6-.3-.2-.3-.1-.8.2-1 .1 0 7.3-4.8 14.4 0 .3.2.4.7.2 1-.2.3-.7.4-1 .2-6.3-4.2-12.7 0-12.8 0-.1 0-.3.1-.4.1zM223.59 746.46c-.2 0-.5-.1-.6-.3-.2-.3-.1-.8.2-1 .1 0 7.3-4.8 14.4 0 .3.2.4.7.2 1-.2.3-.7.4-1 .2-6.3-4.2-12.7 0-12.8 0-.1.1-.3.1-.4.1zM178.49 697.36c-.4 0-.7-.3-.7-.6-.1-.4.2-.8.6-.8l7.7-1.1c.4-.1.8.2.8.6.1.4-.2.8-.6.8l-7.8 1.1zM178.59 702.56c-.4 0-.7-.3-.7-.6-.1-.4.2-.8.6-.8l7.7-1.1c.4-.1.8.2.8.6.1.4-.2.8-.6.8l-7.8 1.1c.1 0 .1 0 0 0zM178.59 708.36c-.4 0-.7-.3-.7-.6-.1-.4.2-.8.6-.8l7.7-1.1c.4-.1.8.2.8.6.1.4-.2.8-.6.8l-7.8 1.1c.1 0 .1 0 0 0z"
      />
      <g className="prefix__st7">
        <path
          className="prefix__st6"
          d="M215.69 375.06l10.3-34.5c.1-.4.5-.6.9-.5.4.1.6.5.5.9l-11.7 39.3v-5.2z"
        />
      </g>
      <g className="prefix__st7">
        <path
          className="prefix__st6"
          d="M347.39 375.76l-10.5-35.2c-.1-.4-.5-.6-.9-.5-.4.1-.6.5-.5.9l11.7 39.3.2-4.5z"
        />
      </g>
      <g className="prefix__st7">
        <path
          className="prefix__st6"
          d="M287.49 517.46c-.2 0-.3-.1-.5-.2-.3-.3-.3-.7-.1-1l13.3-15.5c.3-.3.7-.3 1-.1.3.3.3.7.1 1l-13.3 15.5c0 .2-.2.3-.5.3z"
        />
      </g>
      <path
        className="prefix__st6"
        d="M377.19 223.56l4.5-5.6-5.5-15.5-3.1 19.4c-.2 1.9 2.9 3.2 4.1 1.7zM382.39 275.36c2.3-1.5 3.5-3.6 5.5-7l4-6.2v-58.4l-9.5 71.6z"
      />
      <path
        className="prefix__st2"
        d="M275.99 147.66l25.1-22-3.7-49.8h-41.6l-3.1 50.2 23.3 21.6z"
      />
      <path
        className="prefix__st2"
        d="M302.99 58.36s5.2 57-26.1 57.4c-30.6.4-28.1-56.7-28.1-56.7l.1-16.4 39.6-4.6 9.6 5.7 4.9 14.6z"
      />
      <path
        className="prefix__st12"
        d="M296.39 45.16s10.3-6 14.3 5.5c3.4 9.7-3.9 19.7-3.9 19.7l-5.7-.9c-2.4-.2-4-1.4-4.1-3.8l-.6-20.5z"
      />
      <path
        className="prefix__st12"
        d="M303.89 38.76c.2 10.8-9.2 13.1-12.9 13.2l-31 .8c-2.5.1-4.5 2.1-4.5 4.6v9.8c0 1.3-1 2.5-2.4 2.6l-1.9.1-2.3 2.5c-11.9-3.6-14.9-38.2 7.7-43.8 2.4-.6 4.6-2.2 5.8-4.4 3.6-6.5 17-7.5 23.2.4 1.3 1.7 3 2.5 5.1 2.6 8.2.3 13.1 5.7 13.2 11.6z"
      />
      <path
        className="prefix__st2"
        d="M250.99 73.26c-.2-4-4.8-6.3-8-4l-.1.1c-5.6 4.3-3 18.9 8.7 18.3l-.6-14.4zM309.59 69.06l-.1-.1c-3.3-2.3-7.8.1-7.9 4.1l-.2 13.8c11.8.3 13.8-13.7 8.2-17.8z"
      />
      <path
        className="prefix__st12"
        d="M252.39 75.16c0 .7-.6 1.3-1.4 1.3-.8 0-1.4-.5-1.5-1.1l-.8-9.3 3.8.4-.1 8.7zM303.99 74.36c0 .7-.6 1.3-1.4 1.3-.8 0-1.4-.5-1.5-1.1l-.8-9.3 3.8.4-.1 8.7z"
      />
      <path
        className="prefix__st2"
        d="M290.99 53.36c-.9 0-1.5-.1-1.6-.1-.4 0-.6-.4-.6-.7 0-.4.4-.6.7-.6.1 0 6.2.7 9.1-3.3.2-.3.6-.4.9-.1.3.2.4.6.1.9-2.2 3.3-6.3 3.9-8.6 3.9z"
      />
      <path
        className="prefix__st12"
        d="M266.29 82.16c1.49 0 2.7-1.21 2.7-2.7 0-1.49-1.21-2.7-2.7-2.7s-2.7 1.21-2.7 2.7c0 1.49 1.21 2.7 2.7 2.7z"
      />
      <path
        className="prefix__st10"
        d="M264.99 79.76c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6 0 .33.27.6.6.6z"
      />
      <path
        className="prefix__st12"
        d="M287.19 81.86c1.49 0 2.7-1.21 2.7-2.7s-1.21-2.7-2.7-2.7-2.7 1.21-2.7 2.7 1.21 2.7 2.7 2.7z"
      />
      <path
        className="prefix__st10"
        d="M285.89 79.46c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6-.33 0-.6.27-.6.6 0 .33.27.6.6.6z"
      />
      <g className="prefix__st13">
        <path
          className="prefix__st14"
          d="M277.29 92.96h2.2c.4 0 .8-.4.8-.8s-.4-.8-.8-.8h-2.2c-.9 0-1.6-.7-1.6-1.6s.7-1.7 1.6-1.7c.4 0 .8-.4.8-.8s-.4-.8-.8-.8c-1.8 0-3.2 1.5-3.2 3.2 0 1.9 1.4 3.4 3.2 3.3z"
        />
      </g>
      <path
        className="prefix__st12"
        d="M295.19 72.36H258.09c-2.2 0-3.9 1.7-3.9 3.9v3c0 5.8 4.7 10.6 10.6 10.6 5.8 0 10.6-4.7 10.6-10.6v-3c0-.9-.3-1.7-.8-2.4h4.1c-.5.7-.8 1.5-.8 2.3v3c0 5.8 4.7 10.6 10.6 10.6 5.8 0 10.6-4.7 10.6-10.6v-3c0-2-1.7-3.8-3.9-3.8zm-21.4 3.9v3c0 5-4.1 9-9.1 9s-9-4.1-9-9.1v-3c0-1.3 1.1-2.3 2.4-2.3h13.4c1.3 0 2.4 1.1 2.3 2.4zm23.7 3c0 5-4.1 9-9.1 9s-9-4.1-9-9.1v-3c0-1.3 1.1-2.3 2.4-2.3h13.4c1.3 0 2.3 1.1 2.3 2.4v3z"
      />
      <path
        className="prefix__st10"
        d="M270.39 96.86l13.3-.2c.3 0 .6.3.5.7-2.3 6.7-11.6 6.9-14.2.3v-.1c-.2-.3.1-.7.4-.7z"
      />
      <g className="prefix__st13">
        <path
          className="prefix__st14"
          d="M247.59 80.56c-.4 0-.8-.3-.8-.7l-.1-1.7c-.1-1.8-.5-3-1.3-3.7-.6-.5-1.2-.5-1.3-.5-.4 0-.8-.3-.8-.7s.3-.8.7-.9c.1 0 1.3-.1 2.4.8 1.1.9 1.7 2.6 1.8 4.8l.1 1.7c.1.5-.2.8-.7.9.1 0 .1 0 0 0z"
        />
      </g>
      <g className="prefix__st13">
        <path
          className="prefix__st14"
          d="M305.29 79.76c.4 0 .8-.3.8-.7l.1-1.7c0-1.8.4-3.1 1.1-3.7.6-.6 1.2-.5 1.2-.5.4 0 .8-.3.8-.7 0-.4-.3-.8-.7-.8-.1 0-1.3-.1-2.4.9s-1.7 2.6-1.7 4.9l-.1 1.7c.1.2.4.5.9.6z"
        />
      </g>
      <path
        className="prefix__st8"
        d="M298.99 97.36l.1-.3c-3.4 9.7-9.8 17.5-20 18.6h.1c-.7.1-1.5.1-2.2.1-8.2.1-14.7-4.1-19.3-12.4-.2-.3-.6-.5-1-.3-.3.2-.5.6-.3 1 4.9 8.8 11.8 13.2 20.6 13.1 1.9 0 3.7-.3 5.4-.7 5.4 1.1 11.6.1 17.7-3.8l-1.1-15.3z"
      />
      <path
        className="prefix__st15"
        d="M137.59 685.56l43.6 80.5c.9 1.8 3 2.5 4.9 1.8.4-.1.8-.3 1.1-.5l-47.9-90c-2.5 2-3.2 5.4-1.7 8.2zM210.49 768.16h37.2c2.9 0 5-2.7 4.4-5.5h-46c-.6 2.8 1.5 5.5 4.4 5.5z"
      />
      <path
        className="prefix__st3"
        d="M306.69 245.26l-3.1-1 .1-.5 2.7.9 1.9-3.2.5.3-2.1 3.5z"
      />
      <path className="prefix__st16" d="M997.49 434.16v11.4" />
      <path
        className="prefix__st5"
        d="M998.89 768.86h-583c-7.9 0-14.2-6.4-14.2-14.2v-583c0-7.9 6.4-14.2 14.2-14.2h583c7.9 0 14.2 6.4 14.2 14.2v583c0 7.9-6.3 14.2-14.2 14.2z"
      />
      <path
        className="prefix__st17"
        d="M998.89 768.86h-583c-7.9 0-14.2-6.4-14.2-14.2v-583c0-7.9 6.4-14.2 14.2-14.2h583c7.9 0 14.2 6.4 14.2 14.2v583c0 7.9-6.3 14.2-14.2 14.2z"
      />
      <path
        className="prefix__st5"
        d="M989.09 768.86h-583c-7.9 0-14.2-6.4-14.2-14.2v-583c0-7.9 6.4-14.2 14.2-14.2h583c7.9 0 14.2 6.4 14.2 14.2v583c0 7.9-6.3 14.2-14.2 14.2z"
      />
      <path
        d="M989.09 768.86h-583c-7.9 0-14.2-6.4-14.2-14.2v-583c0-7.9 6.4-14.2 14.2-14.2h583c7.9 0 14.2 6.4 14.2 14.2v583c0 7.9-6.3 14.2-14.2 14.2z"
        opacity={0.7}
        fill="#fff"
      />
      <path
        className="prefix__st2"
        d="M425.09 152.56c0 2.7 2.2 4.8 4.8 4.8h6.6l1.6 16c.1 1.2 1.2 2.2 2.4 2.2 1.3 0 2.3-1 2.4-2.3l1-15.3c0-.2.2-.4.5-.4.2 0 .4.2.5.4l1 15.1c.1 1.4 1.2 2.5 2.6 2.5 1.4 0 2.6-1.1 2.6-2.6l.5-15.3-.5 14.9c-.1 1.6 1.3 3 2.9 3 1.5 0 2.7-1.1 2.9-2.6l1.4-15.2-1 11.3c-.1 1.4 1 2.7 2.4 2.7 1.2 0 2.2-.8 2.4-2l1.9-9.6c.5-2.5-.4-5.1-2.3-6.7-7.7-6.7-18.7-8-27.7-3.1l-8.5 1.7c-.2-.1-.4.2-.4.5z"
      />
      <g className="prefix__st9">
        <path
          className="prefix__st8"
          d="M450.98 157.68l-.47 14.09 1.4.05.47-14.09-1.4-.05z"
        />
      </g>
      <g className="prefix__st9">
        <path
          className="prefix__st8"
          d="M457.48 158.56l-.95 11.36 1.39.12.95-11.36-1.39-.12z"
        />
      </g>
      <path
        className="prefix__st6"
        d="M436.49 157.46h-6.6l4-3.8c.8-.8 2.2-.3 2.3.9l.3 2.9z"
      />
      <path
        className="prefix__st5"
        d="M919.44 677.16H496.86c-9.02 0-16.11-7.3-16.11-16.11V238.47c0-9.02 7.3-16.11 16.11-16.11h422.58c9.02 0 16.11 7.3 16.11 16.11v422.58c0 9.02-7.09 16.11-16.11 16.11z"
      />
      <path
        className="prefix__st17"
        d="M919.44 677.16H496.86c-9.02 0-16.11-7.3-16.11-16.11V238.47c0-9.02 7.3-16.11 16.11-16.11h422.58c9.02 0 16.11 7.3 16.11 16.11v422.58c0 9.02-7.09 16.11-16.11 16.11z"
      />
      <path
        className="prefix__st10"
        d="M898.38 677.16H475.81c-9.02 0-16.11-7.3-16.11-16.11V238.47c0-9.02 7.3-16.11 16.11-16.11h422.58c9.02 0 16.11 7.3 16.11 16.11v422.58c.21 9.02-7.09 16.11-16.12 16.11z"
      />
      <path
        d="M786.67 533.44H587.74c-7.09 0-12.68-5.8-12.68-12.68 0-7.09 5.8-12.68 12.68-12.68h198.94c7.09 0 12.68 5.8 12.68 12.68-.01 7.09-5.6 12.68-12.69 12.68z"
        fill="#61656d"
      />
      <path
        className="prefix__st1"
        d="M838.45 583.06H535.96c-3.65 0-6.66-3.01-6.66-6.66 0-3.65 3.01-6.66 6.66-6.66h302.49c3.65 0 6.66 3.01 6.66 6.66.21 3.65-3.01 6.66-6.66 6.66zM777.43 624.31H597.19c-3.65 0-6.66-3.01-6.66-6.66 0-3.65 3.01-6.66 6.66-6.66h180.25c3.65 0 6.66 3.01 6.66 6.66-.01 3.65-3.01 6.66-6.67 6.66z"
      />
      <path
        className="prefix__st5"
        d="M704.39 275.21H687.2v1.72c-39.96 7.95-70.25 43.4-70.25 85.72s30.08 77.77 70.25 85.72v1.72h17.19c48.34 0 87.44-39.1 87.44-87.44s-39.1-87.44-87.44-87.44z"
      />
      <path
        className="prefix__st5"
        d="M687.2 449.87c48.29 0 87.44-39.15 87.44-87.44s-39.15-87.44-87.44-87.44-87.44 39.15-87.44 87.44c.01 48.29 39.15 87.44 87.44 87.44z"
      />
      <path
        d="M687.2 449.87c48.29 0 87.44-39.15 87.44-87.44s-39.15-87.44-87.44-87.44-87.44 39.15-87.44 87.44c.01 48.29 39.15 87.44 87.44 87.44z"
        opacity={0.4}
        fill="#fff"
      />
      <path
        className="prefix__st10"
        d="M670.88 398.95l-29.43-29.43c-1.5-1.5-1.5-4.08 0-5.8l7.73-7.73c1.5-1.5 4.08-1.5 5.8 0l18.91 18.91 45.54-45.76c1.5-1.5 4.08-1.5 5.8 0l7.73 7.73c1.5 1.5 1.5 4.08 0 5.8l-56.29 56.29c-1.5 1.71-4.08 1.71-5.79-.01z"
      />
      <path
        className="prefix__st16"
        d="M1187.39 771.26H54.49c-.7 0-1.2-.5-1.2-1.2s.5-1.2 1.2-1.9h1132.8c1.2.2 1.2 1.2 1.2 1.9 0 .6-.5 1.2-1.1 1.2z"
      />
	  </g>
    </svg>
  );
}

export default SuccessSvg;

