import { formatDistance, formatDistanceToNow } from 'date-fns';
import { nl } from 'date-fns/locale';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react'
import { Container, Header, Segment, Statistic, Table, Image, Button, Icon, Dropdown, Grid, Popup, Pagination, PaginationItem, Input } from 'semantic-ui-react';
import { getTransactionStatisticsByCustomerId } from '../../Services/BackendService';
import { groupBy } from '../../Services/GroupBy';
import { printDocument } from '../../Services/PrintDocument';
import { saveFile } from '../../Services/SaveFile';
import { TransactionStatistic } from '../../types';
import './TransactionStatisticsByCustomer.css'
type propTypes = {
	match?: any;
}
type order = 'ascending' | 'descending' | undefined;

type state = {
	data: TransactionStatistic[],
	direction: order,
	column: string,
	filter: string[]

}

export type action =
	| { type: "CHANGE_SORT"; data: { column: string } }
	| { type: "SET_DATA"; data: TransactionStatistic[] }

/**
 * Either set the data or set the sort
 * @param state 
 * @param action 
 * @returns 
 */
function tableReducer(state: state, action: action): state {
	switch (action.type) {
		case 'CHANGE_SORT':
			if (state.column === action.data.column) {
				return {
					...state,
					data: [...state.data].reverse(),
					direction:
						state.direction === 'ascending' ? 'descending' : 'ascending',
				}
			}

			return {
				column: action.data.column,
				data: _.sortBy(state.data, [action.data.column]),
				direction: 'ascending',
				filter: state.filter
			}
		case 'SET_DATA':
			return { ...state, data: action.data }
		default:
			throw new Error()
	}
}
const initState: state = {
	data: [],
	direction: undefined,
	column: '',
	filter: []
}

export const TransactionStatisticsByCustomer = (props: propTypes) => {
	const [stats, setStats] = useState<TransactionStatistic[]>([]);
	const [state, dispatch] = useReducer(tableReducer, initState);
	const [activePage, setActivePage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [groupedStats, setGroupedStats] = useState<[string, TransactionStatistic[]][]>();
	const { column, data, direction } = state

	const [filter, setFilter] = useState<string[]>([]); // Active option(s) to filter by
	const [filterOptions, setFilterOptions] = useState<any[]>([]); // Options to filter by service
	const [filterClient, setFilterClient] = useState<string[]>([]); // Options to filter by client
	const [filterOptionsClient, setFilterOptionsClient] = useState<any[]>([]); // Active option(s) to filter by client 

	/**
	 * On route change, update filters accordingly 
	 */
	useEffect(() => {
		if (props.match.params.customerid !== undefined) {
			getTransactionStatisticsByCustomerId(props.match.params.customerid).then(res => {
				setStats(res.data);
				dispatch({ type: "SET_DATA", data: res.data });

				const grouped = groupBy(res.data, x => x.service);
				const groupedClient = groupBy(res.data, x => x.clientName);

				setGroupedStats(Array.from(grouped));
				setFilterOptions(Array.from(grouped.keys()).map(opt => ({ text: opt, value: opt, key: opt })));
				setFilterOptionsClient(Array.from(groupedClient.keys()).map(opt => ({ text: opt, value: opt, key: opt })));

				if (props.match.params.clientName !== undefined) {
					setFilterClient([props.match.params.clientName]);
				}
			});
		}
	}, [props.match.params.customerid, props.match.params.clientName])

	return (<>
		<Container style={{ width: "80%", marginTop: "2em" }}>
			<Segment >
				<Segment floated="left" basic>
					<Header as="h2" floated="left">
						Overzicht
					</Header>
				</Segment>
				<Segment floated="right" basic>
					<Dropdown floating placeholder='Filter per service' search multiple selection options={filterOptions} onChange={(e, d) => setFilter(d.value as any)} />
					<Dropdown floating placeholder='Filter per klant' value={filterClient} search multiple selection options={filterOptionsClient} onChange={(e, d) => setFilterClient(d.value as any)} />
				</Segment>
				<Table sortable celled fixed padded basic="very" id="divToPrint">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell
								sorted={column === 'Client' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'clientName', } })}
							>
								Client
          </Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'txId' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'transactionId', } })}
							>
								txId
          </Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'txStatId' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'transactionStatusId' } })}
							>
								txStatId
          </Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'service' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'service' } })}
							>
								service
          </Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'Validated' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'validated' } })}
							>
								Validated
          </Table.HeaderCell>
							<Table.HeaderCell
								sorted={column === 'Completed (transaction)' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'completedParent' } })}
							>
								Completed (transaction)
          </Table.HeaderCell>
		  <Table.HeaderCell
								sorted={column === 'Tijd geleden' ? direction : undefined}
								onClick={() => dispatch({ type: 'CHANGE_SORT', data: { column: 'date' } })}
							>
								Tijd geleden
          </Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{state.data.filter(tx => filterClient.includes(tx.clientName) || filterClient.length === 0).filter(tx => filter.includes(tx.service) || filter.length == 0).slice((activePage * perPage) - perPage, activePage * perPage).map(({ date, clientName, clientLogo, transactionId, transactionStatusId, service, validated, completedParent }) => (
							<Table.Row key={transactionStatusId}>
								<Table.Cell>
									<Popup mouseEnterDelay={200} mouseLeaveDelay={150} trigger={<Header as='h4' image>
										<Image src={clientLogo} rounded size='mini' />
										<Header.Content>
											{clientName}
											<Header.Subheader>{formatDistanceToNow(Date.parse(date), { locale: nl })} geleden</Header.Subheader>
										</Header.Content>
									</Header>} flowing hoverable>
										<Button.Group basic>
											<Button basic onClick={() => { if (!filterClient.includes(clientName)) { setFilterClient([...filterClient, clientName]) } }}>Voeg <b>{clientName}</b> toe als filter </Button>
											<Button basic icon="mail outline">Stuur <b>{clientName}</b> een e-mail </Button>
										</Button.Group>

									</Popup>

								</Table.Cell>
								<Table.Cell>{transactionId}</Table.Cell>
								<Table.Cell>{transactionStatusId}</Table.Cell>
								<Table.Cell>{service}</Table.Cell>
								<Table.Cell>{validated ? <Icon color='green' name='checkmark' size='large' /> : <Icon color='red' name='close' size='large' />}</Table.Cell>
								<Table.Cell>{completedParent ? <Icon color='green' name='checkmark' size='large' /> : <Icon color='red' name='close' size='large' />}</Table.Cell>
								<Table.Cell>{formatDistanceToNow(Date.parse(date), { locale: nl })} geleden</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
					<Table.Footer>
						<Table.Row >
							<Table.HeaderCell colSpan="7">
								<Grid columns="2" verticalAlign="middle">
									<Grid.Column width="10">
										<Statistic.Group horizontal size="tiny" style={{ display: "flex", flexDirection: "row" }}>
											{
												groupedStats?.filter(x => filter.includes(x[0]) || filter.length == 0).map(group => (
													<Statistic horizontal size="mini" key={"k" + group[1][0].transactionStatusId}>
														<Statistic.Value>{group[1].filter(x => filterClient.includes(x.clientName) || filterClient.length === 0).length}</Statistic.Value>
														<Statistic.Label>{group[0]} API calls</Statistic.Label>
													</Statistic>
												))
											}
										</Statistic.Group>
									</Grid.Column>
									<Grid.Column width="3" >
										<Pagination onPageChange={(e, d) => setActivePage(parseInt(d.activePage?.toString() ?? "0"))} defaultActivePage={1} totalPages={Math.ceil(data.filter(tx => filterClient.includes(tx.clientName) || filterClient.length === 0).filter(tx => filter.includes(tx.service) || filter.length == 0).length / perPage)} />
									</Grid.Column>
								</Grid>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>

				</Table>
				<Button onClick={() => printDocument("Export" + (filterClient.length !== 0 ? filterClient.map(name => " " + name) : " ") + (filter.length !== 0 ? filter.map(name => " " + name) : " ") + " PDF")}>Download export</Button>
				<Button onClick={() => saveFile("export.json", state.data.filter(tx => filterClient.includes(tx.clientName) || filterClient.length === 0).filter(tx => filter.includes(tx.service) || filter.length == 0).map(entry => ({ Client: entry.clientName, ClientId: entry.clientId, txId: entry.transactionId, txStatId: entry.transactionStatusId, service: entry.service, validated: entry.validated, transactionCompleted: entry.completedParent })))}>Download data</Button>
				<Input
					label={{ basic: true, content: 'resultaten per pagina' }}
					labelPosition='right'
					type="number"
					placeholder='10'
					onChange={(e, d) => setPerPage(parseInt(d.value))}
				/>
			</Segment>


		</Container>
	</>
	)
}
