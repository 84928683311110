import axios from "axios";
import { number, string } from "yup/lib/locale";
import { AllClientsDto, authflow, client, ClientCreateEditDto, ClientsByCustomerId, customer, Dictionary, FlowAddDto, GetApiKeysDto, GetCustomerDto, IgetHomeScreenStatisticsByCustomerId, LoginDto, TransactionStatistic } from "../types";

axios.interceptors.request.use((req) => {
    req.withCredentials = true;
    return req;
});

export const getAllClients = () => {
    return axios.get<AllClientsDto[]>("/adminclient/client/allclients");
}

export const toggleClientActive = (id: number) => {
	return axios.put(`adminclient/client/toggleactive/${id}`);
}


export const uploadImage = (file: any, organizationId: string, fileName?: string)=>{
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", fileName || "null");
    formData.append("OrganizationId", organizationId);
    return axios.post("/image", formData);
}

export const getClientsByCustomerId = (id: number) => {
	return axios.get<ClientsByCustomerId[]>(`/admincustomer/customer/clients/${id}`);
}

export const toggleIsActiveCustomerClient = (id: number) => {
	return axios.post(`/admincustomerclient/customerclient/toggleactive/${id}`);
}

export const putClient = (client: ClientCreateEditDto) => {
    
    const expDate = client.apiKey?.expiryDate;
    client.apiKey = undefined;
    client = {...client, apiKey: {expiryDate: expDate}};
    return axios.put<ClientCreateEditDto>("/adminclient/client", client);
}

export const createClient = (client: ClientCreateEditDto) => {
	return axios.post<ClientCreateEditDto>("/adminclient/client/", client);
}

export const getClientByCustomerClientId = (id: number) => {
    return axios.get<ClientCreateEditDto>(`/adminclient/client/${id}`);
}

export const toggleIsActiveFlow = (id: number) => {
	return axios.put(`/adminclient/client/flow/${id}`);	
}


export const createApiKeyNew = (customerclientid: number) => {
    return axios.post(`/adminclient/client/apikey/${customerclientid}`);
}

export const createFlow = (name: string, customerClientId: number, description?: string,) => { 
	return axios.post<FlowAddDto>('/adminclient/client/flow', {name: name, description: description, customerClientId: customerClientId});
}

export const editFlow = (name: string, id: number, order: number[], description?: string) => {
	return axios.put('/adminclient/client/flow', {name: name, description: description ?? "", id: id, order: order});
}


export const createCustomer = (customer: customer) => {
    return axios.post("/admincustomer/customer", customer);
};

export const customerService = (customerId: number, serviceId: number) => {
	return axios.post(`/admincustomer/customer/access/${serviceId}/${customerId}`);
}

export const editCustomer = (customer: customer) => {
    return axios.put("/admincustomer/customer", customer);
};

export const getCustomer = (id: number) => {
    return axios.get<GetCustomerDto>(`/admincustomer/customer/${id}`);
};

export const getRecentCustomers = () => {
    return axios.get<GetCustomerDto[]>(`/admincustomer/customer/recent`);
};

export const getAllCustomers = () => {
	return axios.get<getAllCustomersT[]>("/admincustomer/customer/all");
}

export type getAllCustomersT = {
	name: string,
	id: number,
	serviceIds: number[]
}

export const login = (username: string, password: string) => {
    return axios.post<LoginDto>("/authenticate/login", { username: username, password: password });
};

export const PostFile = (file: any, organizationId: string, fileName?: string) => {
    const formData = new FormData();
    formData.append("FormFile", file);
    formData.append("FileName", fileName || "null");
    formData.append("OrganizationId", organizationId);
    return axios.post("/admin/image", formData);
};

export const getApiKeys = (id: number) => {
	return axios.get<GetApiKeysDto[]>(`/admin/keys/${id}`);
}

export const getAllKeys = (customerId: number) => {
	return axios.get<Dictionary<GetApiKeysDto[]>>(`/admin/keys/all/${customerId}`);
}

export const getTransactionStatisticsByCustomerId = (id: number) => {
	return axios.get<TransactionStatistic[]>("/adminexcelexport/excel/bycustomer/" + id);
}

export const getHomeScreenStatisticsByCustomerId = (id: number) => {
	return axios.get<IgetHomeScreenStatisticsByCustomerId[]>("/adminexcelexport/excel/recentbycustomer/" + id);
}