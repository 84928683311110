import React, { FunctionComponent, useEffect, useMemo, useState } from "react"
import { Button, Container, Grid, Input, Modal, Segment } from "semantic-ui-react";
import ReactMde from "react-mde";
import 'react-mde/lib/styles/css/react-mde-all.css';
import './CreateEditFlowModal.css'

import ReactMarkdown from 'react-markdown'

function exampleReducer(state: any, action: any) {
	switch (action.type) {
		case 'CLEAR_LOG':
			return { ...state, log: [] }
		case 'OPEN_MODAL':
			return {
				log: [
					{
						event: action.event,
						date: new Date().toLocaleTimeString(),
						name: action.name,
						value: true,
					},
					...state.log,
				],
				open: true,
			}
		case 'CLOSE_MODAL':
			return {
				log: [
					{
						event: action.event,
						date: new Date().toLocaleTimeString(),
						name: action.name,
						value: true,
					},
					...state.log,
				],
				open: false,
			}
		default:
			throw new Error()
	}
}

type IProps = {
	header: string,
	content?: any,
	trigger: any
	name: string
	description?: string
	callback: (e: any) => void
}

export const CreateEditFlowModal: FunctionComponent<IProps> = (props: IProps) => {
	const [state, dispatch] = React.useReducer(exampleReducer, {
		log: [],
		open: false,
	})
	const { log, open } = state
	const [name, setName] = useState(props.name)

	const [value, setValue] = useState(props.description ?? `
### Koptekst (ctrl + h) 

**Dikgedrukte tekst (ctrl + b)**

*Italics (ctrl + i)*

Tweemaal enter voor een newline.	
`);
	
	return (
		<Grid>
			<Grid.Column width={4}>
				<Modal
					onOpen={(e) =>
						dispatch({ event: e.type, name: 'onOpen', type: 'OPEN_MODAL' })
					}
					onClose={(e) =>
						dispatch({ event: e.type, name: 'onClose', type: 'CLOSE_MODAL' })
					}
					open={open}
					trigger={props.trigger}
				>
					<Modal.Header>{props.header !== "" ? props.header + " aanpassen" : "Nieuwe flow aanmaken"}</Modal.Header>
					<Modal.Content>
						<Segment basic>
							<Input label='Naam' placeholder='Mijn Nieuwe Flow' onChange={(e) => setName(e.target.value)} fluid value={name} />
						</Segment>
						<Container fluid >
							<Segment.Group>
								<Segment>Welkom scherm tekst</Segment>
								<Segment.Group>
									<div className="containerEditor">
										<ReactMde
											value={value}

											toolbarCommands={[["header", "bold", "italic"]]}
											onChange={setValue}
											disablePreview
										/>
									</div>
								</Segment.Group>
								<Segment>Preview</Segment>
								<Segment.Group>
									<Segment><ReactMarkdown skipHtml source={value} /></Segment>
								</Segment.Group>
							</Segment.Group>
						</Container>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={(e) =>
								dispatch({
									event: e.type,
									name: 'onClick',
									type: 'CLOSE_MODAL',
								})
							}
							negative
						>
							Sluiten
			  </Button>
						<Button
							onClick={(e) => {
								dispatch({
									event: e.type,
									name: 'onClick',
									type: 'CLOSE_MODAL',
								});
								props.callback({ value: {name: name, description: value} });
							}
							}
							positive
						>
							{props.header == "" ? "Aanmaken" : "Aanpassen"}
						</Button>
					</Modal.Actions>
				</Modal>
			</Grid.Column>
		</Grid>
	)
}