import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export function printDocument(name: string) {
    const input = document.getElementById('divToPrint');
	var doc = new jsPDF();
	if(input == null) {
		return;
	}
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
		var imgProps = pdf.getImageProperties(imgData);
        pdf.addImage(imgData, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), (imgProps.height * doc.internal.pageSize.getWidth()) / imgProps.width);
        // pdf.output('dataurlnewwindow');
        pdf.save(`${name}.pdf`);
      })
    ;
  }