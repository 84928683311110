import React, { FunctionComponent, useState } from "react"
import { Button, Grid, Input, Modal, Segment } from "semantic-ui-react"

function exampleReducer(state: any, action: any) {
	switch (action.type) {
		case 'CLEAR_LOG':
			return { ...state, log: [] }
		case 'OPEN_MODAL':
			return {
				log: [
					{
						event: action.event,
						date: new Date().toLocaleTimeString(),
						name: action.name,
						value: true,
					},
					...state.log,
				],
				open: true,
			}
		case 'CLOSE_MODAL':
			return {
				log: [
					{
						event: action.event,
						date: new Date().toLocaleTimeString(),
						name: action.name,
						value: true,
					},
					...state.log,
				],
				open: false,
			}
		default:
			throw new Error()
	}
}

type IProps = {
	header: string,
	content: any,
	trigger: any
	callback: (e: any) => void
}


export const CreateEditOrganizationCallbackUrlModal: FunctionComponent<IProps> = (props: IProps) => {
	const [state, dispatch] = React.useReducer(exampleReducer, {
		log: [],
		open: false,
	})
	const { log, open } = state
	const [redirectUrl, setRedirectUrl] = useState("")

	return (
		<Grid>
			<Grid.Column width={4}>
				<Modal
					onOpen={(e) =>
						dispatch({ event: e.type, name: 'onOpen', type: 'OPEN_MODAL' })
					}
					onClose={(e) =>
						dispatch({ event: e.type, name: 'onClose', type: 'CLOSE_MODAL' })
					}
					open={open}
					trigger={props.trigger}
				>
					<Modal.Header>TBA: Aangepaste redirect {props.header}</Modal.Header>
					<Modal.Content>
						<Input label='https://' placeholder='mysite.com' onChange={(e) => setRedirectUrl(e.target.value)} fluid disabled/>
					</Modal.Content>
					<Modal.Actions>
						<Button
							onClick={(e) =>
								dispatch({
									event: e.type,
									name: 'onClick',
									type: 'CLOSE_MODAL',
								})
							}
							negative
						>
							Sluiten
			  </Button>
						<Button
							onClick={(e) => {
								dispatch({
									event: e.type,
									name: 'onClick',
									type: 'CLOSE_MODAL',
								});
								props.callback({value: redirectUrl});
							}
							}
							positive
						>
							Opslaan
			  </Button>
					</Modal.Actions>
				</Modal>
			</Grid.Column>
		</Grid>
	)
}