import React, { FunctionComponent } from 'react'

type IProps = {
	children: any
}

export const readCookie = (name: string) => {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}

export const IsAdmin = readCookie("Role") === "Admin"
export const MyCustomerId = parseInt(readCookie("CustomerId") ?? "");
export const AuthGate: FunctionComponent<IProps> = (props: IProps) => {


	return readCookie("Role") === "Admin" ? (
		<>
			{props.children}
		</>
	) : null
}

export default AuthGate
