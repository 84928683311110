import React, { FunctionComponent, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { Container, Header, Segment } from "semantic-ui-react";
import * as yup from "yup";
import { APIKeyList } from "./APIKeyList";
import { HomeStatistics } from "./HomeStatistics";


export const Home: FunctionComponent = () => {
	return (
		<Container className="cont">
			<Header as="h4" attached="top">
				Statistieken
		</Header>
			<Segment attached>
				<HomeStatistics />
			</Segment>
			<Header as="h4" attached>
				Actieve API keys
		</Header>
			<Segment attached>
				<APIKeyList />
			</Segment>
		</Container>


	)

}