import React, { FunctionComponent } from 'react'
import { Message } from 'semantic-ui-react'

type IProps = {
	errors: any
}

export const Errors: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<Message style={{display: Object.values(props.errors).length == 0 ? "none" : "block", marginTop: 0}} attached = "bottom" warning header = "Er ging iets mis.." list = { Object.values(props.errors) } />
	)
}
