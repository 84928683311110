import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { List, Grid, Button, Header, Icon, Segment } from "semantic-ui-react";
import { createFlow, editFlow, toggleIsActiveFlow } from "../../Services/BackendService";
import { optionprops, options } from "../../types";
import { StateDispatch, StateValue } from "./Context";
import { CreateEditFlowModal } from "./CreateEditFlowModal";
import { opt } from "./CreateEditOrganization";
import { CreateEditOrganizationCallbackUrlModal } from "./CreateEditOrganizationCallbackUrlModal";
import update from "immutability-helper";
import "./AuthFlowDnD.css"

type AuthFlowDnDProps = {
	customerclientid: string
}

export const AuthFlowDnD = (props: AuthFlowDnDProps) => {
	const dispatch = useContext(StateDispatch);
	const state = useContext(StateValue);

	/**
	 * Reorder the DnD options AND the corresponding flow in state.
	 * @param list List of DnD options to render, NOT the actual flow.
	 * @param startIndex 
	 * @param endIndex 
	 */
	const reorder = (list: opt[], startIndex: any, endIndex: any): opt[] => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		const oldflow = [...state.organization.flows];
		const curr = oldflow[state.currentflow];

		const [removedFlow] = curr.flow.splice(startIndex, 1);
		curr.flow.splice(endIndex, 0, removedFlow);
		oldflow[state.currentflow] = curr;
		dispatch({ type: "setFlow", data: oldflow });

		return result;
	};

	/**
	 * Check if source and destination index is the same, reorder if not.
	 * @param result Contains old (source) index and new (destination) index
	 */
	function onDragEnd(result: any) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const options = reorder(state.options, result.source.index, result.destination.index);

		dispatch({ type: "setOptions", data: options });
	}

	const addAuthFlow = () => {
		createFlow("New Flow", parseInt(props.customerclientid)).then((res) => {
			const newflow = [...state.organization.flows!, { name: res.data.name, flow: [], id: res.data.id }];
			dispatch({ type: "setFlow", data: newflow });
		});
	};

	const changeFlowName = (data: { name: string, description: string }, id: number, index: number) => {
		const curr = state.organization.flows.find(f => f.id === id);
		if (curr !== undefined) {
			if (curr.id === undefined) {
				createFlow(data.name, parseInt(props.customerclientid), data.description).then((res) => dispatch({ type: "setFlow", data: update(state.organization.flows, { [index]: { $merge: { name: data.name, id: res.data.id, description: data.description } } }) }));
			} else {
				editFlow(data.name, curr.id, curr.flow, data.description).then((res) => dispatch({ type: "setFlow", data: update(state.organization.flows, { [index]: { $merge: { name: data.name, description: data.description } } }) }));
			}
		}

	};

	/**
	 * Update a flow, if for some reason the flow to update has no ID (does not exist in database) create one.
	 * @param index 
	 */
	const updateFlow = (id?: number) => {
		const curr = state.organization.flows.find(f => f.id === id);
		if (curr !== undefined) {
			if (curr.id === undefined) {
				createFlow(curr.name, parseInt(props.customerclientid), curr.description);
			} else {
				editFlow(curr.name, curr.id!, curr.flow, curr.description);
			}
		}
	};

	/**
	 * Toggles flow IsActive by id.
	 * @param id Id of flow
	 */
	const removeFlow = (id: number) => {
		toggleIsActiveFlow(id).then(res => {
			// TODO: Update UI to match state.
		})
	}

	/**
	 * Removes service ID from flow.
	 * @param id id of service
	 */
	const removeFromFlow = (id: number) => {
		const newFlows = [...state.organization.flows!];
		newFlows[state.currentflow].flow = newFlows[state.currentflow].flow.filter((e) => e !== id);
		dispatch({ type: "setFlow", data: newFlows });
	};

	/**
	 * Reset current flow (add all available service ids to flow)
	 */
	const resetFlow = () => {
		const old = [...state.organization.flows!];
		old[state.currentflow].flow = [...state.organization?.apiAccess!];
		dispatch({ type: "setFlow", data: old });
	};

	function Option(option: optionprops) {
		const opt = options.find((el) => el.id === option.option);
		return (
			<Draggable draggableId={option.option.toString()} index={option.index}>
				{(provided) => (
					<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						<Segment textAlign="center" style={{ width: "100px" }} attached="top" key={`segOpt${option.option}`}>
							<>
								<p>{opt?.name} </p>
								<Icon name={opt?.icon as any} />
							</>
						</Segment>
						<Segment attached="bottom" textAlign="center">
							<Grid columns={2} divided>
								<Grid.Column>
									<Icon name="remove" size="small" onClick={() => removeFromFlow(option.option)} />
								</Grid.Column>
								<Grid.Column>
									<CreateEditOrganizationCallbackUrlModal
										callback={(e) => console.log(e)}
										trigger={<Icon ref={contextRef as any} name="edit outline"></Icon>}
										header={opt?.name ?? ""}
										content=""></CreateEditOrganizationCallbackUrlModal>
								</Grid.Column>
							</Grid>
						</Segment>
					</div>
				)}
			</Draggable>
		);
	}

	const OptionsList = () => {
		return state.organization.flows![state.currentflow].flow.map((option, index: number) => (
			<span style={{ paddingLeft: "1em", paddingRight: "1em" }} key={`optionDnd${option}`}>
				<Option option={option} index={index} />
			</span>
		));
	};

	const contextRef = React.useRef();

	return (<>
		<Header as="h4" attached>
			Authenticatie flows
		</Header>
		<Segment attached style={{ display: "flex", alignItems: "center" }} loading={state.loading.all}>
			<List horizontal divided relaxed="very" style={{ display: "contents" }}>
				{state.organization.flows.map((el, index) => (
					<List.Item active={state.currentflow === index} key={"apiacceslist" + el.id}>
						<List.Content>
							<Segment
								attached
								className={state.currentflow === index ? "selected" : "standard"}
								onClick={() => {
									dispatch({ type: "setCurrentFlow", data: index });
								}}>
								<List.Header as="h3" className="">
									<h3>{el.name}</h3>
								</List.Header>
								{state.organization.flows[index].flow.map((e, indexm) => options.find((opt) => e === opt.id)?.name + (indexm !== state.organization.flows[index].flow.length - 1 ? " -> " : ""))}
							</Segment>
							<Segment attached="bottom" textAlign="center">
								<Grid columns={3} divided>
									<Grid.Column onClick={() => removeFlow(el.id ?? 0)} style={{cursor: "pointer"}}>
										<Icon name="remove" />
									</Grid.Column>
									<Grid.Column textAlign="center" stretched>
										<CreateEditFlowModal description={el.description} name={el.name} callback={(e) => changeFlowName(e.value, el.id ?? 0, index)} trigger={<Icon name="edit outline" />} header={el.name ?? ""}></CreateEditFlowModal>
									</Grid.Column>
									<Grid.Column onClick={() => updateFlow(el.id)} style={{cursor: "pointer"}}>
										<Icon name="save outline" />
									</Grid.Column>
								</Grid>
							</Segment>
						</List.Content>
					</List.Item>
				))}
				<List.Item>
					<Button basic icon="add" onClick={() => addAuthFlow()} disabled={props.customerclientid === undefined} />
				</List.Item>
			</List>
		</Segment>
		<Segment attached>
			<Header as="h4">Flow Order <Icon size="large" name="undo alternate" onClick={() => (state.organization.flows[state.currentflow] !== undefined ? resetFlow() : "")} disabled={props.customerclientid === undefined} /></Header>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="list" direction="horizontal">
					{(provided) => (
						<div className="horizontallist" ref={provided.innerRef} {...provided.droppableProps}>
							{state.organization.flows![state.currentflow] !== undefined ? OptionsList() : null}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Segment>
	</>)
}
