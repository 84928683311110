import React, { useContext } from "react";
import { getAllCustomersT } from "../../Services/BackendService";
import { organization, isLoading, flow } from "../../types";
import { opt } from "./CreateEditOrganization";

export type state = {
	organization: organization;
	loading: isLoading;
	currentflow: number;
	options: opt[];
	id?: number;
	logo?: any;
	allCustomers: getAllCustomersT[];
	initial: any;
};

export function reducer(state: state, action: action): state {
	switch (action.type) {
		case "setThemeColor":
			return { ...state, organization: { ...state.organization, themeColor: action.data } };
		case "setLogo":
			return { ...state, organization: { ...state.organization, logo: action.data } };
		case "setInitial":
			return { ...state, initial: action.data };
		case "setAllCustomers":
			return { ...state, allCustomers: action.data };
		case "setCurrentFlow":
			return { ...state, currentflow: action.data };
		case "setFlow":
			return { ...state, organization: { ...state.organization, flows: action.data } };
		case "setApiAccess":
			return { ...state, organization: { ...state.organization, apiAccess: action.data } };
		case "setLoading":
			return { ...state, loading: action.data };
		case "setOrganization":
			return { ...state, organization: action.data };
		case "setOptions":
			return { ...state, options: action.data };
		default:
			throw new Error(JSON.stringify(action));
	}
}

export type action =
	| { type: "setFlow"; data: flow[] }
	| { type: "setThemeColor"; data: any }
	| { type: "setApiAccess"; data: number[] }
	| { type: "setLoading"; data: isLoading }
	| { type: "setOrganization"; data: organization }
	| { type: "setOptions"; data: opt[] }
	| { type: "setLogo"; data: any }
	| { type: "setCurrentFlow"; data: number }
	| { type: "setAllCustomers"; data: getAllCustomersT[] }
	| { type: "setInitial"; data: any };

export const initState: state = {
	loading: { all: true, apikey: false, logo: false },
	options: [],
	currentflow: 0,
	organization: { flows: [], themeColor: "#697689" },
	allCustomers: [],
	initial: {},
};

export const StateDispatch = React.createContext<React.Dispatch<action>>(() => null);

export const StateValue = React.createContext<state>(initState);

export const useStateDispatch = () => useContext(StateDispatch);