import { FunctionComponent, useEffect, useState } from "react";
import { List, Header, Button } from "semantic-ui-react";
import { getAllKeys, toggleClientActive } from "../../Services/BackendService";
import { Dictionary, GetApiKeysDto } from "../../types";
import './APIKeyList.css';
import { formatDistanceToNow } from "date-fns/esm";
import { nl } from 'date-fns/locale'
import { MyCustomerId } from "../AuthGate";
import { NavLink } from "react-router-dom";
export const APIKeyList: FunctionComponent = () => {

	const [apiKeys, setApiKeys] = useState<Dictionary<GetApiKeysDto[]>>()

	/**
	 * On init, get all keys associated with own customerId.
	 */
	useEffect(() => {
		getAllKeys(MyCustomerId).then(res => {
			setApiKeys(res.data);
		});
	}, [])


	/**
	 * 
	 * @param id customerClientId
	 * @param key Customer name
	 */
	const toggleActive =(id: number, key: string) => {
		if(window.confirm(`Organisatie wordt uitgezet.`)){
			toggleClientActive(id).then(res => {
				var old: Dictionary<GetApiKeysDto[]> = {};
				Object.assign(old, apiKeys);
				old![key] = old![key].filter(k => k.customerClientId !== id);
				setApiKeys(old);
			});
		}
		
	}

	return (
		<div>
			{apiKeys !== undefined ? Object.keys(apiKeys).map((key, index) =>
			(<div style={index !== 0 ? {paddingTop: "3em" } : {}}>
				<List.Header className="listheader">
					<Header as="h3"> {key} </Header>
				</List.Header>
				<List divided relaxed verticalAlign="middle" style={{ maxHeight: "100%", overflowY: "auto" }} key={`${key}43`}>
					{apiKeys[key].map(data =>
					(<>
						<List.Item className="flex-container" key={data.apiKey}>
							<List.Content floated="left" className="flex-item">
								<List.Header as='h4'>{data.clientName}</List.Header>
								<List.Description as='a'><strong>{data.expiryDate !== null && data.expiryDate !== undefined ? `Vervalt over ${formatDistanceToNow(Date.parse(data.expiryDate), { locale: nl })}` : "Geen vervaldatum"} </strong> {data.serviceAccess !== undefined ? data.serviceAccess.map(service => (<strong key={data.apiKey + service}> {service} </strong>)) : "Geen toegang tot API's"}</List.Description>
							</List.Content>
							<span></span>
							<List.Content floated="right" className="flex-item">
								<Button.Group basic>
									<Button icon="pie graph" as={NavLink} to={`/dashboard/stats/${MyCustomerId}/${data.clientName}`}  content="Statistieken" />
									<Button icon="edit outline" content="Bewerk" as={NavLink} to={`/organization/${data.customerClientId}`}/>
									<Button icon="delete" content="Verwijder" onClick={() => toggleActive(data.customerClientId!, key)} />
								</Button.Group>
							</List.Content>
						</List.Item>
					</>))}
				</List>
			</div>))
				: null}
		</div>
	)
}
